<template>
  <section id="edit-purchases">
    <b-row>
      <b-col>
        <h1>{{ $t("purchases.titleEdit") }}</h1>
        <h5 class="text-primary">
          {{ $t("purchases.subtitleEdit") }}
        </h5>
      </b-col>
      <b-col class="ml-2 text-right pr-5">
        <router-link to="/platform/purchases">
          <b-button variant="danger">
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <b-row class="match-height mt-2">
      <b-col>
        <b-form @submit.prevent="updateDatos">
          <b-overlay
            variant="transparent"
            :show="showDatos"
          >
            <b-card>
              <b-card-header>
                <b-card-title>{{ $t("purchases.subtitleEdit") }}</b-card-title>
                <b-button
                  type="submit"
                  variant="success"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col
                    lg="12"
                    class="mb-1"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('purchases.label4')"
                    >
                      <b-form-input
                        v-model="name"
                        class="mr-1 "
                        minlength="3"
                      />
                    </b-form-group>
                    <b-row v-if="!isManual">
                      <b-col md="4">
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('purchases.code')"
                        >
                          <b-form-input
                            v-model="code"
                            class="mr-1"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group
                          class="mb-2"
                          :label="$t('purchases.price')"
                        >
                          <div class="d-flex align-items-center">
                            <b-form-input
                              v-model="price"
                              type="number"
                              :min="0"
                              step="any"
                            />
                            <strong class="ml-1">
                              {{ currencyType[currency] }}
                            </strong>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group
                          class="mb-2"
                          :label="$t('purchases.stripePriceId')"
                        >
                          <b-form-input v-model="stripePriceId" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group label="">
                          <b-form-radio-group
                            id="btn-radios-1"
                            v-model="typePurchase"
                            :options="optionsElemento"
                            button-variant="outline-primary"
                            size="lg"
                            name="radios-btn-default"
                            buttons
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-row v-if="typePurchase">
                          <b-col>
                            <b-form-group :label="$t('modal.content')">
                              <validation-provider
                                v-slot="{ errors }"
                                name="content"
                                rules="required"
                              >
                                <b-col
                                  cols="13"
                                  class="mb-1 "
                                  @click="selectElement(0)"
                                >
                                  <b-form-input
                                    id="content"
                                    v-model="contentName"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('modal.content')"
                                    disabled
                                  />
                                </b-col>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="4"
                            class="d-flex align-items-center "
                          >
                            <b-button
                              variant="info"
                              @click="selectElement(0)"
                            >
                              {{ $t("purchases.selectContent") }}
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row v-else>
                          <b-col>
                            <b-form-group :label="$t('modal.category')">
                              <validation-provider
                                v-slot="{ errors }"
                                name="category"
                                rules="required"
                              >
                                <b-col
                                  cols="13"
                                  class="mb-1"
                                  @click="selectElement(1)"
                                >
                                  <b-form-input
                                    id="category"
                                    v-model="categoryName"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('modal.category')"
                                    disabled
                                  />
                                </b-col>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="4"
                            class="d-flex align-items-center "
                          >
                            <b-button
                              variant="info"
                              @click="selectElement(1)"
                            >
                              {{ $t("purchases.selectCategory") }}
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="subgroups"
                    md="4"
                  >
                    <b-form-group
                      v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                      :label="$t('Subgroup')"
                    >
                      <v-select
                        id="subgroup"
                        v-model="subgroup"
                        :options="subgroups"
                        label="name"
                        :placeholder="$t('SinGrupoUsuario')"
                        aria-autocomplete="new-password"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-form>
      </b-col>
    </b-row>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector
          :is-premium="true"
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="categorySelectorTable"
          :is-premium="true"
          @confirm-selection="CategorySelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormRadioGroup,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, showToast, getSubgroups, checkPermissions,
  isEmpty,
} from '@/store/functions'

import { required } from '@validations'

import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import ContentSelector from '../../common/ContentSelector.vue'
import CategorySelector from '../../common/CategorySelector.vue'

export default {
  components: {
    ContentSelector,
    CategorySelector,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,
    BOverlay,
    vSelect,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      name: null,
      price: 0,
      stripePriceId: null,
      contentName: null,
      categoryName: null,
      idContent: null,
      idCategory: null,
      code: null,
      userData: getUserData(),
      isManual: null,
      typePurchase: true,
      optionsElemento: [
        { text: this.$t('purchases.content'), value: true },
        { text: this.$t('purchases.category'), value: false },
      ],
      catTableData: null,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
      currency: null,
      showDatos: false,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      inapppurchaseContent: [],
      inapppurchaseCategory: [],
      checkPermissions,
    }
  },

  mounted() {
    this.getData()
    this.allCategory()
    this.getPayMethod()

    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
    }
  },
  methods: {
    getData() {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
            query{
                allPurchases(id:"${id}",client:"${this.userData.profile.client.id}"){                    
                  edges{
                    node{
                      id
                      name
                      price
                      stripePriceId
                      isManual
                      code
                      subgroup{
                        id
                        name
                      }
                      inapppurchaseCategory{
                        totalCount
                      edges{
                        node{
                          id
                          name
                        }
                      }
                    }
                    inapppurchaseContent{
                      totalCount
                      edges{
                        node{
                          id
                          name
                        }
                      }
                    }
                    }
                  }
                }        
            }
        `,
        })
        .then(res => {
          messageError(res, this)
          const purchase = res.data.data.allPurchases.edges[0].node
          this.name = purchase.name
          this.price = purchase.price
          this.stripePriceId = purchase.stripePriceId
          this.isManual = purchase.isManual
          this.code = purchase.code
          this.subgroup = purchase?.subgroup
          this.idContent = ''
          this.idCategory = ''
          this.contentName = ''
          this.categoryName = ''
          if (purchase.inapppurchaseContent.totalCount !== 0) {
            this.inapppurchaseContent = purchase.inapppurchaseContent.edges
            this.idContent = purchase.inapppurchaseContent.edges[0].node.id
            this.contentName = purchase.inapppurchaseContent.edges[0].node.name
            this.typePurchase = true
          }
          if (purchase.inapppurchaseCategory.totalCount !== 0) {
            this.inapppurchaseCategory = purchase.inapppurchaseCategory.edges
            this.idCategory = purchase.inapppurchaseCategory.edges[0].node.id
            this.categoryName = purchase.inapppurchaseCategory.edges[0].node.name
            this.typePurchase = false
          }
        })
    },
    updateDatos(event) {
      this.showDatos = true

      event.preventDefault()
      const { id } = this.$route.params

      const arrayContent = this.inapppurchaseContent.map(e => e.node.id)
      const arrayCategory = this.inapppurchaseCategory.map(e => e.node.id)

      let queryContent = ''
      let queryCategory = ''

      if (!isEmpty(this.idContent) || !isEmpty(this.idCategory)) {
        if (this.typePurchase || arrayCategory.length > 1) {
          queryCategory = arrayCategory
            .map(idCategory => `c_${idCategory}:updateCategory(id:"${idCategory}",input:{inapppurchase:""}) {
          category { id }
        }`).join('\n')
        } else if (!this.typePurchase || arrayContent.length > 1) {
          queryContent = arrayContent
            .map(idContent => `c_${idContent}:updateContents(id:"${idContent}",input:{inapppurchase:""}) {
          content { id }
        }`).join('\n')
        }
      }

      const query = `
          mutation($stripePriceId: String,$code: String, $subgroup: ID){  
            updatePurchases(id: "${id}",input:{
              name: "${this.name}",
              price: ${this.price},
              subgroup: $subgroup,
              stripePriceId: $stripePriceId,
              code: $code,
              }){
              inAppPurchase{
                id
                name
                price
                stripePriceId
                code
                subgroup{
                  id
                  name  
                }
                inapppurchaseCategory{
                      edges{
                        node{
                          id
                          name
                        }
                      }
                    }
                    inapppurchaseContent{
                      edges{
                        node{
                          id
                          name
                        }
                      }
                    }                
                  }
                }
                  ${this.typePurchase ? `updateContents(id:"${this.idContent}",input:{
                inapppurchase:"${id}",
                }){
                  content{
                    id
                  }
                }${queryContent}` : queryContent}
              
                ${!this.typePurchase ? `updateCategory(id:"${this.idCategory}",input:{
                inapppurchase:"${id}",
                }){
                  category{
                    id
                  }
                }${queryCategory}` : queryCategory}
              }   
        `
      axios
        .post('', {
          query,
          variables: {
            stripePriceId: this.stripePriceId,
            code: this.code,
            subgroup: this.subgroup?.id,
          },
        })
        .then(res => {
          messageError(res, this)
          showToast(this.$t('code.updateData'), 1, this)
          this.getData()

          this.showDatos = false
        })
        .catch(res => {
          console.log(res)
          this.getData()

          showToast(this.$t('code.updateDataError'), 2, this)
          this.showDatos = false
        })
    },
    selectElement(elemento) {
      switch (elemento) {
        case 0:
          this.showModal()
          break
        case 1:
          this.$refs['cat-modal'].show()

          break

        default:
          break
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.$refs['cat-modal'].hide()
    },
    SelectContentId(data) {
      this.idContent = data
      this.hideModal()
    },
    SelectContentUrl(data) {
      this.contentName = data
      this.hideModal()
    },
    CategorySelection(data) {
      this.categoryName = data.node.name
      this.idCategory = data.node.id
      // console.log(data);
      this.hideModal()
    },
    allCategory() {
      axios
        .post('', {
          query: `
            query{  
            allCategories(client:"${this.userData.profile.client.id}"){
              edges{
                node{
                  id
                  name
                  image
                  mediaLocation
                  imageUrl
                }
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.catTableData = res.data.data.allCategories.edges
          // console.log(this.catTableData);
        })
    },

    getPayMethod() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
              query{
                client(id:"${id}"){                  
                  currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#edit-purchases .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#edit-purchases .card-body h4 {
  font-size: 1.286rem !important;
}
</style>
